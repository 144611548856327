import React, { useState } from 'react';
import { 
  Box, TextField, Paper, Typography, Button, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Snackbar, Alert, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

const EditorContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  color: theme.palette.text.primary
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const TranscriptionEditor = () => {
  const [jsonInput, setJsonInput] = useState('');
  const [editableData, setEditableData] = useState(null);
  const [transcriptOrder, setTranscriptOrder] = useState([]);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          setJsonInput(e.target.result);
          
          const template = {
            hints: {},
            speakers: {}
          };
          
          const orderedTranscripts = [];
          
          if (json.transcription) {
            json.transcription.forEach(segment => {
              const text = segment.segment.text.trim();
              const speaker = segment.segment.speaker;
              
              if (text) {
                template.hints[text] = '';
                orderedTranscripts.push({
                  text,
                  speaker,
                  start: segment.segment.start
                });
              }
              if (speaker && !template.speakers[speaker]) {
                template.speakers[speaker] = '';
              }
            });
          }
          
          orderedTranscripts.sort((a, b) => a.start - b.start);
          setTranscriptOrder(orderedTranscripts);
          setEditableData(template);
          setError('');
        } catch (err) {
          setError('Invalid JSON file');
          setShowSnackbar(true);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleSpeakerChange = (originalSpeaker, newSpeaker) => {
    setEditableData(prev => ({
      ...prev,
      speakers: {
        ...prev.speakers,
        [originalSpeaker]: newSpeaker
      }
    }));
  };

  const handleHintChange = (text, value) => {
    setEditableData(prev => ({
      ...prev,
      hints: {
        ...prev.hints,
        [text]: value
      }
    }));
  };

  const downloadJSON = () => {
    try {
      const originalData = JSON.parse(jsonInput);
      const processed = {
        ...originalData,
        transcription: originalData.transcription.map(segment => ({
          ...segment,
          hint: editableData.hints[segment.segment.text.trim()] || '',
          segment: {
            ...segment.segment,
            speaker: editableData.speakers[segment.segment.speaker] || segment.segment.speaker
          },
          words: segment.words ? segment.words.map(word => ({
            ...word,
            speaker: editableData.speakers[word.speaker] || word.speaker
          })) : []
        }))
      };

      const blob = new Blob([JSON.stringify(processed, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'processed_transcription.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      setShowSnackbar(true);
    } catch (err) {
      setError('Processing failed');
      setShowSnackbar(true);
    }
  };

  return (
    <EditorContainer>
      <StyledPaper>
        <Typography variant="h4" gutterBottom>Transcription Editor</Typography>
        
        <Button variant="contained" component="label" sx={{ mb: 2 }}>
          Upload JSON
          <input type="file" hidden accept=".json" onChange={handleFileUpload} />
        </Button>

        {editableData && (
          <>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {Object.entries(editableData.speakers).map(([speaker, newName]) => (
                <Grid item xs={12} sm={6} md={4} key={speaker}>
                  <TextField
                    fullWidth
                    label={`Speaker: ${speaker}`}
                    value={newName}
                    onChange={(e) => handleSpeakerChange(speaker, e.target.value)}
                  />
                </Grid>
              ))}
            </Grid>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Speaker</TableCell>
                    <TableCell>Text</TableCell>
                    <TableCell>Hint</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transcriptOrder.map(({text, speaker}) => (
                    <TableRow key={text}>
                      <TableCell>
                        {editableData.speakers[speaker] || speaker}
                      </TableCell>
                      <TableCell>{text}</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          value={editableData.hints[text] || ''}
                          onChange={(e) => handleHintChange(text, e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 2 }}>
              <Button variant="contained" onClick={downloadJSON}>
                Download
              </Button>
            </Box>
          </>
        )}
      </StyledPaper>

      <Snackbar 
        open={showSnackbar} 
        autoHideDuration={6000} 
        onClose={() => setShowSnackbar(false)}
      >
        <Alert 
          severity={error ? "error" : "success"}
          onClose={() => setShowSnackbar(false)}
        >
          {error || "Processing successful!"}
        </Alert>
      </Snackbar>
    </EditorContainer>
  );
};

export default TranscriptionEditor;