import React from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import TranscriptionEditor from './components/TranscriptionEditor';

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1e1e1e',
      paper: '#2d2d2d',
    },
    primary: {
      main: '#2196f3',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <TranscriptionEditor />
    </ThemeProvider>
  );
}

export default App;